import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

export const productImage = graphql`
  fragment productImage on File {
    childImageSharp {
      fluid(maxHeight: 400, quality: 90) {
        ...GatsbyImageSharpFluid
        presentationWidth
      }
    }
  }
`;

const ProductsImage = ({ image }) => {
  const data = useStaticQuery(graphql`
    query {
      orFaceFerme: file(relativePath: { eq: "products/or/face-ferme.jpeg" }) {
        ...productImage
      }
      orFaceOuvert: file(relativePath: { eq: "products/or/face-ouvert.jpeg" }) {
        ...productImage
      }
      orProfilOuvert: file(relativePath: { eq: "products/or/profil-ouvert.jpeg" }) {
        ...productImage
      }
      orProfilFerme: file(relativePath: { eq: "products/or/profil-ferme.jpeg" }) {
        ...productImage
      }
      argentFaceFerme: file(relativePath: { eq: "products/argent/face-ferme.jpeg" }) {
        ...productImage
      }
      argentFaceOuvert: file(relativePath: { eq: "products/argent/face-ouvert.jpeg" }) {
        ...productImage
      }
      argentProfilOuvert: file(relativePath: { eq: "products/argent/profil-ouvert.jpeg" }) {
        ...productImage
      }
      argentProfilFerme: file(relativePath: { eq: "products/argent/profil-ferme.jpeg" }) {
        ...productImage
      }
    }
  `);

  const fluid = data[image].childImageSharp.fluid;
  return <Img fluid={fluid} style={{ maxWidth: fluid.presentationWidth, margin: '0 auto'}}/>;
};

export default ProductsImage;
