import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import ProductsImage from '../components/Images/ProductsImage';
import './shop.css';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Eshop" />

    <div className="content page">
      <h1>Eshop</h1>
      <p>
        <i>En cours de finalisation...</i><br /><br/>
        Pour vos premières commandes veuillez envoyer un email à <a href="mailto:contact@liee.fr">contact@liee.fr</a>.
      </p>


      <div className="product-images">
        <ProductsImage image="argentFaceOuvert" />
        <ProductsImage image="argentProfilFerme" />
        <ProductsImage image="argentFaceFerme" />
        <ProductsImage image="argentProfilOuvert" />
      </div>

      <div className="product">
        <div className="product-description">
          <h2>Argent massif rhodié</h2>
          <div className="models">
            <div>
              <h3>Femme</h3>
              <span className="price">490 euros</span>
            </div>
            <div>
              <h3>Enfant</h3>
              <span className="price">470 euros</span>
            </div>
          </div>
        </div>
      </div>

      <div className="product-images">
        <ProductsImage image="orFaceOuvert" />
        <ProductsImage image="orProfilFerme" />
        <ProductsImage image="orFaceFerme" />
        <ProductsImage image="orProfilOuvert" />
      </div>

      <div className="product">
        <div className="product-description">
          <h2>Argent doré</h2>
          <div className="models">
            <div>
              <h3>Femme</h3>
              <span className="price">520 euros</span>
            </div>
            <div>
              <h3>Enfant</h3>
              <span className="price">490 euros</span>
            </div>
          </div>
        </div>
      </div>

      <div className="product-images">
        <ProductsImage image="orFaceOuvert" />
        <ProductsImage image="orProfilFerme" />
        <ProductsImage image="orFaceFerme" />
        <ProductsImage image="orProfilOuvert" />
      </div>

      <div className="product">
        <div className="product-description">
          <h2>Or massif 18 carats</h2>
          <div className="models">
            <div>
              <h3>Femme</h3>
              <span className="price">1370 euros</span>
            </div>
            <div>
              <h3>Enfant</h3>
              <span className="price">1300 euros</span>
            </div>
          </div>
          <span className="price">Existe en or jaune, or blanc et or rose</span>
        </div>
      </div>

      <p>
        Pour vos premières commandes veuillez envoyer un email à <a href="mailto:contact@liee.fr">contact@liee.fr</a>. <br/><br/>
        Le délai moyen de livraison est de 2 semaines.
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
